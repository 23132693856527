import React from 'react'

import { Layout } from 'components/global'
import { PageHero, SignUpForm } from 'components/blocks'

const TeamPage = () => {
  const meta = {
    title: `Sign up | Dorothy Dandridge`,
  }
  return (
    <Layout title={meta.title}>
      <PageHero title="Sign up" />
      <SignUpForm />
    </Layout>
  )
}

export default TeamPage
